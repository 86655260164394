const keycloakLogin = (realm: string) => {
  // @ts-ignore
  /*
  const keycloak = new Keycloak({
    url:
      process.env.NODE_ENV === 'development'
        ? `${process.env.REACT_APP_BASE_URL}/auth`
        : `${window.location.origin}/auth`,
    realm,
    clientId: 'backoffice-application', // TODO: Create new Keycloak client and change this
  });

  keycloak
    .init({ onLoad: 'login-required', checkLoginIframe: false })
    .then((authenticated: boolean) => {
      if (authenticated) {
        window.location.href = process.env.NODE_ENV === 'development'
          ? `http://localhost:4003/${realm}`
          : `${window.location.origin}/${realm}`;
      }
    })
    .finally(() => {
      localStorage.removeItem('abb/portal-login/realm');
    });
  */
  window.location.href = process.env.NODE_ENV === 'development'
    ? `http://localhost:4003/opx/tenant/${realm}`
    : `${window.location.origin}/opx/tenant/${realm}`;
};

export default keycloakLogin;
